<template>
    <van-nav-bar
            fixed
            class="nav-header"
            :left-arrow="!$route.meta.hideBlack"
            :title="$route.meta.title"
            @click-left="onClickLeft"
    >
        <template #left v-if="!$route.meta.hideBlack">
            <van-icon color="#6f6f6f" size="20" name="arrow-left"/>
        </template>
    </van-nav-bar>
</template>

<script>
    export default {
        methods: {
            // 返回上一页
            onClickLeft() {
                this.$router.go(-1)
            }
        },
        mounted() {
            // console.log(this.$router)
        }
    }
</script>