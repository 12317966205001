import router from "./index";

// 获取token
import {getToken} from "../utils/cookies";

// 白名单，不用验证登录与否
const whiteList = ['/login'];

router.beforeEach(async (to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }

    // 判断路由存在白名单
    if (whiteList.indexOf(to.path.toLowerCase()) !== -1) {
        // 白名单
        // 已经登录，跳转到首页
        if (getToken()) {
            if (to.path.toLowerCase() == '/login') {
                // 跳转管理员首页
                next({path: '/index'})
            }
        }
        next()
    } else {
        // 未登录
        if (!getToken()) {
            next({path: '/login', replace: true})
        }
        // 已登录
        next()
    }
})

router.afterEach(() => {
    setTimeout(() => {
        if (document.documentElement.scrollTop) {
            document.documentElement.scrollTop = 0
        }
        if (document.body.scrollTop) {
            document.body.scrollTop = 0
        }
    }, 100)
})