<template>
    <div id="contractApp">
        <router-view/>
    </div>
</template>

<script>
    export default {
        name: 'app',
    }
</script>

<style lang="less">
    #contractApp {
        font-family: "PingFang-SC", Helvetica, Arial, sans-serif;
        color: @color-191b21;
        height: 100%;
        font-size: 30px;
    }
</style>
