import Cookies from 'js-cookie'
import storage from 'store'

let TokenKey = '400_token'

export function getToken() {
    return storage.get(TokenKey)
}

export function setToken(token) {
    storage.set(TokenKey, token)
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    storage.remove(TokenKey)
    return Cookies.remove(TokenKey)
}
