<template>
    <div class="main-wrap">
        <!--顶部导航-->
        <Nav/>
        <!--主体内容-->
        <main class="van-main">
            <router-view/>
        </main>
    </div>
</template>

<script>
    import Nav from "../../components/Nav";

    export default {
        name: "NavMainTab",
        components: {Nav},
        mounted() {
            // console.log(this.$router)
        }
    }
</script>

<style scoped lang="less">
    .main-wrap {
        height: 100%;
    }

    .van-main {
        padding-top: 44px;
        height: calc(100% - 44px);
        background-color: #f7f9fa;
    }
</style>