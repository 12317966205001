import Vue from 'vue'
import VueRouter from 'vue-router'
import NavMain from "../views/layout/NavMain"; // 头部

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Distributor',
        meta: {title: '400分销商'},
        component: NavMain,
        redirect: {name: 'Index'},
        children: [
            {
                path: 'index',
                component: () => import( '../views/index'),
                name: 'Index',
                meta: {title: '400选号服务渠道中心', hideBlack: true}
            },
            {
                path: 'login',
                component: () => import( '../views/login'),
                name: 'Login',
                meta: {title: '登录', hideBlack: true}
            },
            {
                path: 'personal',
                component: () => import( '../views/personal'),
                name: 'Personal',
                meta: {title: '个人中心'}
            },
            {
                path: 'personal/collection',
                component: () => import( '../views/personal/collection'),
                name: 'Collection',
                meta: {title: '收款账号'}
            },
            {
                path: 'returns',
                component: () => import( '../views/returnsDetailed'),
                name: 'ReturnsDetailed',
                meta: {title: '收益明细'}
            },
            {
                path: 'billing',
                component: () => import( '../views/billingDetails'),
                name: 'BillingDetails',
                meta: {title: '结算明细'}
            },
            {
                path: 'regular',
                component: () => import( '../views/regular'),
                name: 'Regular',
                meta: {title: '分佣规则'}
            },
            {
                path: 'case',
                component: () => import( '../views/case'),
                name: 'Case',
                meta: {title: '展示案例'}
            },
            {
                path: 'caseDetails',
                component: () => import( '../views/case/details'),
                name: 'CaseDetails',
                meta: {title: '案例详情'}
            }
        ]
    },
    {path: '*', redirect: '/index'}
]

const router = new VueRouter({
    // mode: 'history',
    routes
})

export default router